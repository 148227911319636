export const constantRoute=[
    {
        path:'/login',
        component:()=>import('@/views/login/index.vue'),
        name:'login',
        meta:{
            title:"登录",
            hidden:true
        }
    },
    {
        path:'/',
        component:()=>import('@/layout/index.vue'),
        name:'',
        meta:{
            title:'',
            hidden:false,
            icon:'House'
        },
        redirect: '/home',
        children:[
            {
                path:'/home',
                component:()=>import('@/views/home/index.vue'),
                name:'home',
                meta: { 
                   title:'首页',
                   hidden:false,
                   icon:'House'
                },
            },{
                path:'/task',
                component:()=>import('@/views/task/index.vue'),
                name:'task',
                meta:{
                    title:"任务",
                    hidden:false
                }
            }
        ]
    },{
        path:'/:pathMatch(.*)*',
        redirect:'/login',
        name:'Any',
        meta: { 
            title:'any',
            hidden:true
         }
    },
]