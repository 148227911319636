<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>

</script>

<style>
body{
  margin:0
}
</style>
