import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import {router} from './router'
import {createPinia} from 'pinia'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// import './permission'

const app=createApp(App)
const pinia=createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(ElementPlus,{locale:zhCn})
app.use(pinia)
app.use(router)
for(const[key,component] of Object.entries(ElementPlusIconsVue)){
    app.component(key,component)
}
app.mount('#app')
