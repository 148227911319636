import { createRouter,createWebHashHistory } from "vue-router";
import { constantRoute } from "./router";

export const router=createRouter({
    history:createWebHashHistory(),
    routes:constantRoute
})



